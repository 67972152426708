<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="유해위험기계기구 기본정보" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-detail">
          <div v-if="editable && equipInfo.plantName" class="col-3">
            <c-label-text title="사업장" :value="equipInfo.plantName"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.machineryClassificationName" class="col-3">
            <c-label-text title="기계분류" :value="equipInfo.machineryClassificationName"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.machineryName" class="col-3">
            <c-label-text title="기계명" :value="equipInfo.machineryName"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.itemNo" class="col-3">
            <c-label-text title="item No" :value="equipInfo.itemNo"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="tableImpr"
        title="개선 목록"
        :columns="gridImpr.columns"
        :data="equipInfo.equipmentImproveModels"
        :merge="gridImpr.merge"
        :gridHeight="gridImpr.height"
        selection="multiple"
        rowKey="equipImproveId"
        :usePaging="false"
        :filtering="false"
        :columnSetting="false"
        :editable="editable"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem" />
            <c-btn v-if="editable && equipInfo.equipmentImproveModels.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem" />
            <c-btn
              v-if="editable && equipInfo.equipmentImproveModels.length > 0"
              :url="insertUrl"
              :isSubmit="isSave"
              :param="equipInfo.equipmentImproveModels"
              mappingType="POST"
              label="저장"
              icon="save"
              @beforeAction="saveImprove"
              @btnCallback="saveCallback" 
            />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
            <q-chip
              v-for="(item, index) in $comm.ibmTagItems(props.row, colorItems)"
              :key="index"
              :color="item.ibmClassCd === 'IC00000001' ? 'blue-6' : 'orange'"
              :clickable="true"
              text-color="white"
              style="margin-bottom:4px !important;"
              @click="linkClick(item, props.row)">
              {{item.title}}
              <c-assess-tooltip 
                :ibmTooltip="item.ibmTooltip"
                :ibmClassCd="item.ibmClassCd"
              />
            </q-chip>
          </template>
          <div v-if="props.row.editFlag!=='C'">
            <q-btn-group outline class="ColumInnerBtnGroup">
              <q-btn
                v-if="editable"
                icon="add"
                color="blue-6"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.prevent="innerBtnClicked(col, props, '1')">
                <q-tooltip>
                  개선요청등록
                </q-tooltip>
              </q-btn>
                <q-btn
                  v-if="editable"
                  icon="add"
                  color="orange"
                  text-color="white"
                  class="ColumInnerBtn"
                  align="center"
                  @click.prevent="innerBtnClicked(col, props, '2')">
                  <q-tooltip>
                    즉시조치등록
                  </q-tooltip>
                </q-btn>
            </q-btn-group>
          </div>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazard-cause-prevention',
  props: {
    equipInfo: {
      type: Object,
      default: () => ({
        hhmHazardousMachineryId: '',
        equipmentCd: '',
        plantName: '',
        machineryClassificationName: '',
        itemNo: '',
        machineryName: '',
        emplacementMonth: '',
        inspectionSubjectFlagName: '',
        inspectionCycleName: '',
        gradeName: '',
        resultName: '',
        departmentDeptName: '',
        departmentUserName: '',
        inspectionKindName: '',
        equipmentImproveModels: []
      }),
    },
  },
  data() {
    return {
      editable: true,
      gridImpr: {
        columns: [
          {
            required: true,
            name: 'locationDetail',
            field: 'locationDetail',
            label: '세부위치',
            align: 'left',
            type: 'text',
            style: 'width:25%',
            sortable: false,
          },
          {
            required: true,
            name: 'findings',
            field: 'findings',
            label: '발견사항',
            align: 'left',
            type: 'text',
            style: 'width:25%',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:20%',
                type: 'custom',
                sortable: false
              },
              {
                name: 'improveMeasures',
                field: 'improveMeasures',
                label: '개선대책',
                align: 'left',
                style: 'width:20%',
                type: 'text',
                sortable: false
              },
            ]
          },
          {
            name: 'equipTypeName',
            field: 'equipTypeName',
            label: '구분',
            align: 'center',
            style: 'width:10%',
            sortable: false,
          },
        ],
        
        data: [],
        height: '700px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        popupParam: {},
        closeCallback: null,
      },
      isSave: false,
      checkCdItems: [], // 검사결과
      getUrl: '',
      items: [],
      getStdUrl: '',
      insertUrl: '',
      deleteUrl: '',
      colorItems: [],
      component: null,
    };
    
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.insertUrl = transactionConfig.sop.hhm.hazard.hazardImprove.insert.url;
      this.deleteUrl = transactionConfig.sop.hhm.hazard.hazardImprove.delete.url;
      
    },
    /* eslint-disable no-unused-vars */
    innerBtnClicked(col, props, btn) {
      this.requestImprRow = props.row
      let requestContents = props.row.accidentCause;
      if (btn === '1') {
        this.popupOptions.title = '개선 요청';
        this.popupOptions.param = {
          requestContents: this.equipInfo.machineryName,
          relationTableKey: props.row.equipImproveId,
          ibmTaskTypeCd: props.row.equipTypeCd === '1' ? 'ITT0000055' : 'ITT0000050',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequest.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          requestContents: this.equipInfo.machineryName,
          relationTableKey: props.row.equipImproveId,
          ibmTaskTypeCd: props.row.equipTypeCd === '1' ? 'ITT0000055' : 'ITT0000050',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmediate.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      }
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$emit('saveCallback', this.equipInfo.hhmHazardousMachineryId)
        this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;

      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
        let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
        if (splitId && splitId.length > 0) {
          let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
          splitStep[idIndex] = result.col1.ibmStepName;
          this.requestImprRow.ibmStepNames = splitStep.toString();
        }
        this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
      }
      this.$emit('saveCallback', this.equipInfo.hhmHazardousMachineryId)
    },
    addItem() {
      this.equipInfo.equipmentImproveModels.splice(0, 0, {
        hhmHazardousMachineryId: this.equipInfo.hhmHazardousMachineryId,
        equipImproveId: uid(),
        locationDetail: '',
        equipTypeCd: '2',
        improvingMeasures: '',
        sopImprovementIds: '', 
        ibmStepNames: '', 
        improveMeasures: '',
        findings: '',
        editFlag: 'C',
        fileNm: '',
      })
    },
    removeItem() {
      let selectData = this.$refs['tableImpr'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('saveCallback', this.equipInfo.hhmHazardousMachineryId)
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveImprove() {
      let isConti = true;
      let checkItem = ['locationDetail', 'findings' ]
      this.$_.forEach(this.equipInfo.equipmentImproveModels, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [세부위치, 발견사항]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } 
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', this.equipInfo.hhmHazardousMachineryId)
    },
  }
};
</script>